import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Divider,
  TablePagination,
} from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DashBoardPage({ setFormType }) {
  const [bills, setBills] = useState([]);
  const [statusCounts, setStatusCounts] = useState({
    READY_FOR_REVIEW: 0,
    REVIEWED: 0,
    PROCESSED: 0,
    REJECTED: 0,
  });
  const [roles, setRoles] = useState([]);
  const chartOptions = {
    maintainAspectRatio: true, // or false
    responsive: true,
  };
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5); // Set to 5 bills per page

  useEffect(() => {
    let storedRoles = [];
    try {
      storedRoles = JSON.parse(localStorage.getItem('roles')) || [];
    } catch (error) {
      console.error('Error parsing roles from localStorage:', error);
    }
    setRoles(storedRoles);

    const fetchBills = async () => {
      try {
        const response = await fetch("https://test.datso.io/get-all-invoices");
        // const response = await fetch("https://test.datso.io/get-all-invoices");
        if (!response.ok) throw new Error("Failed to fetch bills from API.");
        const result = await response.json();

        console.log("Fetched bills response:", result); // Debug log

        // Check if the result is an array or wrapped in an object
        if (Array.isArray(result.invoices)) {
          setBills(result.invoices);
        } else if (Array.isArray(result)) {
          setBills(result);
        } else {
          console.error("Unexpected API response format:", result);
          setBills([]); // Fallback to an empty array
        }

        const counts = {
          READY_FOR_REVIEW: 0,
          REVIEWED: 0,
          PROCESSED: 0,
          REJECTED: 0,
        };
        
        (result.invoices || result).forEach((bill) => {
          const status = bill.bill_status;
          if (status && counts.hasOwnProperty(status)) {
            counts[status] += 1;
          }
        });
        
        setStatusCounts(counts);
        
      } catch (err) {
        console.error("Error fetching bills:", err);
        setBills([]); // Fallback to an empty array in case of error
      }
    };

    fetchBills();
  }, []);

  const filterBillsByRole = (bill) => {
    const status = bill.bill_status;
    if (roles.includes('admin')) return true;
    if (roles.includes('Finance_Team') && status === 'READY_FOR_PAYMENT') return true;
    if (roles.includes('Backend_Team') && status === 'READY_FOR_REVIEW') return true;
    if (roles.includes('Stock_Management_Team') && status === 'REVIEWED') return true;
    return false;
  };

  const filteredBills = bills.filter(filterBillsByRole);

  // Handle pagination changes
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const paginatedBills = filteredBills.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );

  const chartData = {
    labels: ['Ready for Review', 'Reviewed', 'Processed', 'Rejected'],
    datasets: [
      {
        data: [
          statusCounts.READY_FOR_REVIEW || 0,
          statusCounts.REVIEWED || 0,
          statusCounts.PROCESSED || 0,
          statusCounts.REJECTED || 0,
        ],
        backgroundColor: ['#FFCC99', '#CCCCFF', '#CCFFCC', '#FFAD99'],
      },
    ],
  };
  
  console.log("Chart Data:", chartData);
  console.log("Status Counts:", statusCounts);
  
  return (
    <Box
      sx={{
        flexGrow: 1,
        margin: '2%',
        borderRadius: '15px',
        backgroundColor: '#F4F6F9',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        padding: 4,
        marginTop: '-1%',
        marignLeft: '-0.9%',
        width: '98%',
      }}
    >
      {/* Cards Section */}
      <Grid container spacing={4} justifyContent="center" mb={5}>
        {[
          { label: 'Properties', img: 'building.png', color: '#FFEEF3', action: () => setFormType('Create New Land Deal') },
          { label: 'Invoices', img: 'invoice.png', color: '#E6F7FF', action: () => setFormType('View Bills') },
          { label: 'Inventory', img: 'inventory.png', color: '#E6FFF2', action: () => setFormType('View Inventory') },
          { label: 'Task Manager', img: 'prioritize.png', color: '#FFF7E6' },
        ].map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              onClick={card.action}
              sx={{
                cursor: 'pointer',
                borderRadius: '12px',
                background: 'white',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <CardMedia
                  component="img"
                  image={`https://avenuenxt.s3.ap-southeast-1.amazonaws.com/${card.img}`}
                  sx={{ height: '70px', width: '70px', margin: 'auto' }}
                />
                <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold', color: '#2A3663' }}>
                  {card.label}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Bills List and Task Analysis Side by Side */}
      <Grid container spacing={4}>
        {/* Bills List */}
        <Grid item xs={12} md={6}>
          <Card sx={{ borderRadius: '12px', background: 'white' }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#2A3663' }}>
                Bills List
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {paginatedBills.map((bill) => (
                <Card key={bill.avenue_created_invoice_id} sx={{ mb: 2, padding: 2, borderRadius: '8px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Invoice ID: {bill.avenue_created_invoice_id}
                  </Typography>
                  <Typography variant="body2">Status: {bill.bill_status}</Typography>
                </Card>
              ))}

              {/* Pagination Controls */}
              <TablePagination
                component="div"
                count={filteredBills.length}
                page={currentPage}
                onPageChange={handlePageChange}
                rowsPerPage={itemsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Task Analysis Chart */}
        <Grid item xs={12} md={6}>
          <Card sx={{ borderRadius: '12px', textAlign: 'center', background: 'white' }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#2A3663' }}>
                Task Analysis
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ height: '350px', display: 'flex', justifyContent: 'center' }}>
                  <Box sx={{ width: '100%', maxWidth: '300px', height: '100%' }}>
                    <Pie data={chartData} options={chartOptions} />
                  </Box>
                </Box>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}