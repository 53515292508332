import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddInventoryModal from "./AddInventoryModal";
import CreateInventoryModal from "./CreateInventoryModal";
import CloseIcon from "@mui/icons-material/Close";

const ReviewedForm = ({ invoiceData = {}, onClose }) => {
  const invoice = invoiceData || {};
  const items = invoice.items || [];
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAddInventoryOpen, setIsAddInventoryOpen] = useState(false);
  const [isCreateInventoryOpen, setIsCreateInventoryOpen] = useState(false);
  const [openPdfDialog, setOpenPdfDialog] = useState(false);

  const {
    avenue_created_invoice_id = "NA",
    supplier_name = "NA",
    supplier_address = "NA",
    supplier_gstin = "NA",
    invoice_date = "NA",
    invoice_number = "NA",
    file_url = "NA",
  } = invoice;

  const handleClick = (e, index) => {
    const isChecked = e.target.checked;
    const product = items[index];

    if (isChecked) {
      setSelectedRows((prev) => [...prev, product]);
    } else {
      setSelectedRows((prev) => prev.filter((item) => item !== product));
    }
  };

  const handleAddInventory = () => setIsAddInventoryOpen(true);
  const handleCloseAddInventory = () => setIsAddInventoryOpen(false);

  const handleCreateInventory = () => setIsCreateInventoryOpen(true);
  const handleCloseCreateInventory = () => setIsCreateInventoryOpen(false);

  const handlePdfDialogOpen = () => setOpenPdfDialog(true);
  const handlePdfDialogClose = () => setOpenPdfDialog(false);

  return (
    <Box sx={{ padding: 4, backgroundColor: "#F9F9F9", minHeight: "100vh" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#2A3663" }}>
          Invoice Overview #{avenue_created_invoice_id}
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <IconButton onClick={handlePdfDialogOpen} color="primary">
            <VisibilityIcon />
          </IconButton>
          <Button
            variant="contained"
            onClick={handleAddInventory}
            disabled={selectedRows.length === 0}
          >
            Add Inventory
          </Button>
          <Button
            variant="contained"
            onClick={handleCreateInventory}
            disabled={selectedRows.length === 0}
          >
            Create Inventory
          </Button>
          <IconButton
            onClick={onClose}
            sx={{
              color: "#555",
              backgroundColor: "#f0f0f0",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "#d32f2f",
                color: "#fff",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "8px", padding: "20px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", marginBottom: "20px" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#555" }}>
          Vendor Name: {supplier_name}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>
          Vendor Address: {supplier_address}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>
          GSTIN: {supplier_gstin}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>
          Date: {invoice_date}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>
          Invoice Number: {invoice_number}
        </Typography>
      </Box>

      {items.length === 0 ? (
        <Typography>No items available for this invoice.</Typography>
      ) : (
        <TableContainer component={Paper} sx={{ marginBottom: "20px", borderRadius: "8px" }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#2A3663" }}>
              <TableRow>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Item Description</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Shorthand</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Quantity</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Remaining Quantity</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Delivered Quantity</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Code</TableCell>
              
              
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={selectedRows.includes(item)}
                    onChange={(e) => handleClick(e, index)}
                    disabled={item.remaining_quantity === 0 || item.received_status === "RECEIVED"}
                  />
                  {item.description || "NA"}
                </TableCell>
                <TableCell>{item.shorthand || "NA"}</TableCell>
                <TableCell>{item.quantity || "NA"}</TableCell>
                <TableCell>{item.remaining_quantity}</TableCell>
                <TableCell>{item.delivered_quantity}</TableCell>
                <TableCell>{item.code || "NA"}</TableCell>
              </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={isAddInventoryOpen} onClose={handleCloseAddInventory} maxWidth="md">
        <AddInventoryModal
          materialDetails={selectedRows}
          invoiceData={invoiceData}
          onClose={handleCloseAddInventory}
        />
      </Dialog>

      <Dialog open={isCreateInventoryOpen} onClose={handleCloseCreateInventory} maxWidth="md" fullWidth>
        <CreateInventoryModal
          materialDetails={selectedRows}
          invoiceData={invoiceData}
          onClose={handleCloseCreateInventory}
        />
      </Dialog>

      <Dialog open={openPdfDialog} onClose={handlePdfDialogClose} fullWidth maxWidth="lg">
        <DialogTitle>Invoice PDF</DialogTitle>
        <DialogContent>
          <iframe
            src={file_url}
            width="100%"
            height="700px"
            style={{ border: "none" }}
            title="Invoice PDF"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePdfDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReviewedForm;
