import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  Tooltip,
  DialogContent,
  DialogActions,
  TableSortLabel,
  TablePagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";

const InventoryList = () => {
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "item_name", direction: "asc" });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Fetch inventory data
  useEffect(() => {
    const fetchInventory = async () => {
      setLoading(true);
      try {
        const response = await fetch("https://test.datso.io/all-inventory");
        if (!response.ok) {
          throw new Error(`Failed to fetch inventory. Status: ${response.status}`);
        }
        const data = await response.json();
        setInventory(data.inventory);
      } catch (err) {
        console.error("Error fetching inventory:", err);
        setError(err.message || "Unable to fetch inventory. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchInventory();
  }, []);

  // Filter inventory based on search query
  const filteredInventory = inventory.filter((item) => {
    const query = searchQuery.toLowerCase().trim();
    return (
      (item.item_name && item.item_name.toLowerCase().includes(query)) ||
      (item.location && item.location.toLowerCase().includes(query)) ||
      (item.warehouse && item.warehouse.toLowerCase().includes(query)) ||
      (item.quantity && item.quantity.toString().includes(query))
    );
  });

  // Sort inventory
  const sortedInventory = [...filteredInventory].sort((a, b) => {
    const key = sortConfig.key;
    if (a[key] < b[key]) return sortConfig.direction === "asc" ? -1 : 1;
    if (a[key] > b[key]) return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  // Handle sort changes
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Handle QR Code dialog
  const handleQrCodeClick = (itemName, qrCodePath) => {
    setQrCodeData({ itemName, qrCodePath });
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setQrCodeData(null);
  };

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Paginate inventory
  const paginatedInventory = sortedInventory.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box
      sx={{
        padding: 2,
        maxWidth: "100%",
        margin: "auto",
        backgroundColor: "#F9F9F9",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 3,
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "black" }}>
          Inventory List
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search Inventory"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": { borderRadius: "25px" },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Typography color="error" sx={{ marginTop: 2, textAlign: "center" }}>
          {error}
        </Typography>
      )}

      {!loading && !error && (
        <>
          <TableContainer component={Paper} sx={{ borderRadius: "8px" }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#2A3663" }}>
                  {["item_name", "location", "updated_date_ist_human", "warehouse", "quantity"].map(
                    (key) => (
                      <TableCell sx={{ color: "#fff" }} key={key}>
                        <TableSortLabel
                          active={sortConfig.key === key}
                          direction={sortConfig.key === key ? sortConfig.direction : "asc"}
                          onClick={() => handleSort(key)}
                        >
                          {key.replace(/_/g, " ").toUpperCase()}
                        </TableSortLabel>
                      </TableCell>
                    )
                  )}
                  <TableCell sx={{ color: "#fff" }}>QR Code</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedInventory.length > 0 ? (
                  paginatedInventory.map((item) => (
                    <TableRow key={item.inv_id}>
                      <Tooltip title={item.item_name} placement="top">
                        <TableCell>{item.item_name || "Unknown"}</TableCell>
                      </Tooltip>
                      <TableCell>{item.location || "Unknown"}</TableCell>
                      <TableCell>{item.updated_date_ist_human || "Unknown"}</TableCell>
                      <TableCell>{item.warehouse || "Unknown"}</TableCell>
                      <TableCell>{item.quantity || 0}</TableCell>
                      <TableCell>
                        {item.qr_code_path ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleQrCodeClick(item.item_name, item.qr_code_path)}
                          >
                            View QR Code
                          </Button>
                        ) : (
                          "No QR Code"
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                      No items found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={sortedInventory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogContent sx={{ textAlign: "center" }}>
          {qrCodeData ? (
            <>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                {qrCodeData.itemName}
              </Typography>
              <img
                src={qrCodeData.qrCodePath}
                alt="QR Code"
                style={{ maxWidth: "100%", maxHeight: "400px", marginBottom: "16px" }}
              />
            </>
          ) : (
            <Typography>No QR Code Available</Typography>
          )}
        </DialogContent>
        <DialogActions>
          {qrCodeData && (
            <Button
              href={qrCodeData.qrCodePath}
              download={`${qrCodeData.itemName}-QRCode.png`}
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
            >
              Download
            </Button>
          )}
          <Button onClick={handleDialogClose} variant="outlined" color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InventoryList;