import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";
import Slide from "@mui/material/Slide";

const CreateInventoryModal = ({ materialDetails, onClose, invoiceData }) => {
  const [selectedItems, setSelectedItems] = useState(
    materialDetails.map((item) => ({
      ...item,
      isSelected: true,
      receivedQuantity: item.quantity,
      location: "",
      warehouse: "",
    }))
  );
  const userEmail = localStorage.getItem("email");
  const [openDialog, setOpenDialog] = useState(false);
  const [qrData, setQrData] = useState([]);
  const [dialogSeverity, setDialogSeverity] = useState("success");
  const [dialogMessage, setDialogMessage] = useState("");

  const handleCheckboxChange = (index) => {
    setSelectedItems((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, isSelected: !item.isSelected } : item
      )
    );
  };

  const handleFieldChange = (index, field, value) => {
    setSelectedItems((prev) =>
      prev.map((item, i) => (i === index ? { ...item, [field]: value } : item))
    );
  };

  const showDialog = (qrResults, severity, message = "") => {
    setQrData(qrResults);
    setDialogSeverity(severity);
    setDialogMessage(
      message ||
        (severity === "success"
          ? "Inventory created successfully!"
          : severity === "warning"
          ? "Some items already exist. Please review the warnings."
          : "An error occurred while creating inventory.")
    );
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    if (dialogSeverity === "success") {
      onClose();
    }
  };

  const downloadImage = async (imageUrl, itemName) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${itemName}_image.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download image:", error);
    }
  };

  const handleSubmit = async () => {
    const invalidItems = selectedItems.filter(
      (item) =>
        item.isSelected &&
        (item.receivedQuantity === "" || item.location === "" || item.warehouse === "")
    );

    if (invalidItems.length > 0) {
      showDialog([], "error", "Please fill the required fields.");
      return;
    }

    const payload = {
      updated_items: selectedItems.map((item) => ({
        item_name: item.description,
        location: item.location,
        warehouse: item.warehouse,
        avenue_created_invoice_id: invoiceData.avenue_created_invoice_id,
        count_change: Number(item.receivedQuantity),
        email: userEmail,
      })),
    };

    try {
      const response = await fetch("https://test.datso.io/update-inventory", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (response.ok) {
        const warnings = result.details.filter((message) =>
          message.toLowerCase().includes("already present")
        );

        const qrResults = Object.entries(result.qr_codes).map(([item, qr]) => ({
          item,
          qr,
        }));

        if (warnings.length > 0) {
          showDialog(
            qrResults,
            "warning",
            `Some items already exist: ${warnings.join(", ")}`
          );
        } else {
          showDialog(qrResults, "success", result.message);
        }
      } else {
        showDialog([], "error", result.message || "Failed to update inventory.");
      }
    } catch (error) {
      console.error("Error updating inventory:", error);
      showDialog([], "error", "Error updating inventory. Please try again.");
    }
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "20px", fontWeight: "bold" }}>
        Create Inventory
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Item Details</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Received Quantity</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Warehouse</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={item.isSelected}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    size="small"
                    value={item.receivedQuantity}
                    onChange={(e) =>
                      handleFieldChange(index, "receivedQuantity", e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={item.location}
                    onChange={(e) => handleFieldChange(index, "location", e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={item.warehouse}
                    onChange={(e) => handleFieldChange(index, "warehouse", e.target.value)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
        <Button onClick={onClose} sx={{ marginRight: "10px" }}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Create Inventory
        </Button>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        TransitionComponent={Slide}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor:
              dialogSeverity === "success"
                ? "#d1edb4"
                : dialogSeverity === "warning"
                ? "#fff8e1"
                : "#f2b2ac",
            border:
              dialogSeverity === "success"
                ? "2px solid #388e3c"
                : dialogSeverity === "warning"
                ? "2px solid #fbc02d"
                : "2px solid #d32f2f",
          },
        }}
      >
        <DialogTitle
          sx={{
            color:
              dialogSeverity === "success"
                ? "#388e3c"
                : dialogSeverity === "warning"
                ? "#f57c00"
                : "#d32f2f",
          }}
        >
          {dialogSeverity === "success"
            ? "Success"
            : dialogSeverity === "warning"
            ? "Warning"
            : "Error"}
        </DialogTitle>
        <DialogContent>
          {dialogSeverity === "success" || dialogSeverity === "warning" ? (
            <>
              <Typography>{dialogMessage}</Typography>
              {qrData.map(({ item, qr }) => (
                <Box key={item} sx={{ margin: "10px 0" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {item}
                  </Typography>
                  <img
                    src={qr}
                    alt={`QR for ${item}`}
                    width={150}
                    style={{ margin: "10px 0" }}
                    onError={(e) => {
                      e.target.src = "/placeholder-image.png"; // Fallback image
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => downloadImage(qr, item)}
                    sx={{ marginRight: "10px" }}
                  >
                    Download QR
                  </Button>
                </Box>
              ))}
            </>
          ) : (
            <Typography>{dialogMessage}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateInventoryModal;
