import React, { useState, useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import BillsList from "./components/BillsList";
import UploadInvoice from "./components/UploadInvoice";
import MainPage from "./components/MainApp";
import ComingSoonPage from "./components/ComingSoonPage";
import LoginPage from "./components/LoginPage";
import ItemDetails from "./components/ItemDetails";
import DashBoardPage from "./components/DashBoardPage";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  // Load stored session data from localStorage
  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    const storedEmail = localStorage.getItem("email");
    const storedRole = localStorage.getItem("role");
    const storedFirstName = localStorage.getItem("first_name");
    const storedLastName = localStorage.getItem("last_name");

    if (storedIsLoggedIn && storedEmail && storedRole && storedFirstName && storedLastName) {
      setIsLoggedIn(true);
      setEmail(storedEmail);
      setRole(storedRole);
      setFirstName(storedFirstName);
      setLastName(storedLastName);
    }
  }, []);

  // Handle successful login
  const handleLoginSuccess = async (email, navigate) => {
    try {
      const response = await fetch(`https://test.datso.io/roles?email=${email}`);
      // const response = await fetch(`http://localhost:8080/roles?email=${email}`);

      if (!response.ok) throw new Error(`Error: ${response.statusText}`);

      const data = await response.json();

      if (data && data.role && data.first_name && data.last_name) {
        setIsLoggedIn(true);
        setEmail(email);
        setRole(data.role);
        // setRole(data.role.join(", "));
        setFirstName(data.first_name);
        setLastName(data.last_name);

        // Store data in localStorage
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("email", email);
        // localStorage.setItem("role", data.role);
        localStorage.setItem("role", data.role.join(", "));
        localStorage.setItem("first_name", data.first_name);
        localStorage.setItem("last_name", data.last_name);

        logEvent("login");
        navigate("/home");
      } else {
        navigate("/comingsoon");
      }
    } catch (error) {
      console.error("Login failed: ", error);
    }
  };

  // Handle manual logout
  const handleLogout = (navigate) => {
    logEvent("logout");
    clearSession();
    navigate("/");
  };

  // Clear session and localStorage data
  const clearSession = () => {
    setIsLoggedIn(false);
    setEmail(null);
    setRole(null);
    setFirstName(null);
    setLastName(null);
    clearTimeout(timeoutId);

    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
  };

  // Log events to backend
  const logEvent = (action) => {
    fetch("https://test.datso.io/log", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        action,
        page: window.location.pathname,
        timestamp: new Date().toISOString(),
      }),
    });
  };


  return (
    <GoogleOAuthProvider clientId="956925433530-3rkehhq6gir119e5735tfci7tlouh6un.apps.googleusercontent.com">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to="/home" />
              ) : (
                <LoginPage onLoginSuccess={handleLoginSuccess} />
              )
            }
          />
          <Route
            path="/home"
            element={
              isLoggedIn ? (
                <MainPage
                  role={role}
                  userEmail={email}
                  userFirstName={firstName}
                  userLastName={lastName}
                  onLogout={(navigate) => handleLogout(navigate)}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/bills"
            element={
              isLoggedIn ? (
                <BillsList role={role} userEmail={email} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/upload"
            element={
              isLoggedIn ? (
                <UploadInvoice role={role} userEmail={email} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route path="/comingsoon" element={<ComingSoonPage />} />
          <Route path="/:itemName" element={<ItemDetails />} />
        
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;