import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SummarizeIcon from '@mui/icons-material/Summarize';
import ReviewedHistory from "./ReviewedHistory";
const ReviewedHistoryTable = ({ invoiceData, onClose }) => {
  const data = invoiceData;
  const [openPdfDialog, setOpenPdfDialog] = useState(false);
  const handlePdfDialogOpen = () => setOpenPdfDialog(true);
  const handlePdfDialogClose = () => setOpenPdfDialog(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");
  const [showReviewedHistoryForm, setShowReviewedHistoryForm] = useState(false);
  return (
    <Card
      sx={{
        boxShadow: 3,
        borderRadius: "16px",
        overflow: "hidden",
        backgroundColor: "#F9FAFC",
        maxWidth: "90%",
        margin: "auto",
        position: "relative",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          color: "#2A3663",
          textAlign: "center",
          marginBottom: 3,
          marginTop: "30px"
        }}
      >
        Reviewed History Table
      </Typography>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          color: "#555",
          "&:hover": { color: "#D32F2F" },
        }}
      >
        <CloseIcon />
      </IconButton>
      <CardContent>
        <Box sx={{ margin: "auto", 
            marginLeft: "30px",
             marginRight: "30px",
              border:"2px solid grey",
              borderRadius:"10px" }}>
<TableContainer component={Paper} sx={{ marginTop: 4 }}>
  <Table>
    <TableHead>
      <TableRow>
      <TableCell>Avenue Created ID</TableCell>
      <TableCell>Payment Status</TableCell>
      <TableCell>Supplier Name</TableCell>
      <TableCell>Invoice Number</TableCell>
      <TableCell>Created Date</TableCell>
      <TableCell>Invoice </TableCell>
      <TableCell>History</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell>{data.avenue_created_invoice_id}</TableCell>
        <TableCell>{data.payment_status}</TableCell>
        <TableCell>{data.supplier_name}</TableCell>
        <TableCell>{data.invoice_number}</TableCell>
        <TableCell>{data.created_date}</TableCell>
        <TableCell> <IconButton
                    onClick={() => {
                      const fileUrl = data.file_url; // Fetch the file URL dynamically
                      if (fileUrl) {
                        setSelectedPdfUrl(fileUrl); // Set the selected PDF URL
                        setOpenPdfDialog(true); // Open the PDF dialog
                      } else {
                        console.error("No PDF URL found for this bill.");
                        alert("No file available for this invoice.");
                      }
                    }}
                    color="primary"
                  >
                    <VisibilityIcon />
                  </IconButton></TableCell>
                  <TableCell> <IconButton
                        onClick={() => setShowReviewedHistoryForm(true)}
                    color="primary"
                  >
                    <SummarizeIcon />
                  </IconButton></TableCell>
      </TableRow>
    </TableBody>
  </Table>
</TableContainer>
        </Box>
        {showReviewedHistoryForm && (
        <Box
          sx={{
            position: "fixed",
            top: "3%",
            left: "50%",
            transform: "translate(-50%, 0)",
            width: "100%",
            height: "100%",
            maxWidth: "1840px",
            maxHeight: "84vh",
            overflowY: "auto",
            backgroundColor: "transparent",
            borderRadius: "8px",
            zIndex: 1300,
            padding: 2,
            marginTop: "26px",
          }}
        >
          <ReviewedHistory
            onClose={() => setShowReviewedHistoryForm(false)}
            invoiceData={data}
          />
        </Box>
      )}
        <Dialog open={openPdfDialog} onClose={handlePdfDialogClose} fullWidth maxWidth="lg">
          <DialogTitle>Invoice PDF</DialogTitle>
          <DialogContent>
            <iframe
              src={data.file_url}
              width="100%"
              height="700px"
              style={{ border: "none" }}
              title="Invoice PDF"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePdfDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};
export default ReviewedHistoryTable;
