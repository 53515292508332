import React, { useEffect, useState } from "react";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const ItemTypeDropDown = ({ onSelect, value }) => {
  const [itemTypes, setItemTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchItemTypes = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://test.datso.io/get-all-masteritems");
      if (response.ok) {
        const data = await response.json();
        const uniqueItemTypes = Array.from(
          new Set(data.items.map((item) => item.item_type))
        );
        setItemTypes(uniqueItemTypes);
      } else {
        console.error("Failed to fetch items:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItemTypes();
  }, []);

  return (
    <FormControl fullWidth variant="outlined" >
      <InputLabel>Item Type</InputLabel>
      <Select
        value={value || ""}  // Set the value of the select dropdown based on the passed prop
        onChange={(event) => onSelect(event.target.value)}  // Callback when selection changes
        label="Item Type"
        displayEmpty
        disabled={loading}  // Disable select if loading
      >
        {loading ? (
          <MenuItem value="" disabled>
            <CircularProgress size={24} />
          </MenuItem>
        ) : (
          itemTypes.map((itemType, index) => (
            <MenuItem key={index} value={itemType}>
              {itemType}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
};

export default ItemTypeDropDown;