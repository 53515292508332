import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import debounce from "lodash.debounce";

const UploadTaxInvoice = ({ invoiceData, onClose }) => {
  const data = invoiceData;

  useEffect(() => {
    if (invoiceData) {
      setInvoiceNumber(invoiceData.invoice_number || "");
      setAvenueCreatedId(invoiceData.avenue_created_invoice_id || "");
      setVendorName(invoiceData.supplier_name || "");
    }
  }, [invoiceData]);


  console.log("data for override", data);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [avenueCreatedId, setAvenueCreatedId] = useState("");

  const [vendorName, setVendorName] = useState(""); // New state for vendor name
  const [file, setFile] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [documentType, setDocumentType] = useState("Invoice");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success");
  const userEmail = localStorage.getItem("email");
  const [checkingDuplicate, setCheckingDuplicate] = useState(false);

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
    console.log("File selected:", event.target.files[0]);
  };

 
  const handleVedorIdChange = (e) => {
    const value = e.target.value;
    setAvenueCreatedId(value);
  };

  const handleVendorNameChange = (e) => {
    const value = e.target.value;
    setVendorName(value); // Update vendor name state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!invoiceNumber ||!avenueCreatedId|| !file || !vendorName) { // Add vendor name validation
      showDialog("Please fill in all required fields.", "error");
      return;
    }
    
    if (isDuplicate) {
      showDialog(
        "Invoice number is already in use. Please choose a different one.",
        "error"
      );
      return;
    }
    const metadata = JSON.stringify({
      avenueId: avenueCreatedId,
      invoiceNumber: invoiceNumber,
      avenueCreatedId:avenueCreatedId,
      remarks: remarks,
      document_type: documentType,
      vendor_name: vendorName, // Include vendor name in the metadata
      email: userEmail,
      
    });
    
    const formData = new FormData();
    formData.append("file", file);
    formData.append("metadata", metadata);

    try {
      setIsDisabled(true);
      const response = await fetch("https://test.datso.io/upload-tax-invoice", {
        method: "POST",
        body: formData,
      });

      console.log("Is Api called", response);
      if (response.ok) {
        showDialog("Invoice uploaded successfully and sent to REVIEW.", "success");
        setInvoiceNumber("");
        setAvenueCreatedId("")
        setVendorName(""); // Reset vendor name field
        setFile(null);
        setRemarks("");
        setDocumentType("Invoice");
      } else {
        const errorData = await response.json();
        console.error("API Error Response:", errorData);

        if (
          errorData.detail?.error === "Duplicate Invoice Detected" &&
          errorData.detail.old_avenue_id
        ) {
          const errorMessage = `Duplicate Invoice Found: Invoice Number '${invoiceNumber}' is already linked to Avenue ID '${errorData.detail.old_avenue_id}'. Please change the Invoice Number.`;
          showDialog(errorMessage, "error");
        } else {
          const genericMessage =
            errorData.detail?.message || "An unexpected error occurred. Please try again.";
          showDialog(genericMessage, "error");
        }
      }
    } catch (error) {
      console.error("Submission Error:", error);
      showDialog("Failed to upload invoice. Please try again later.", "error");
    } finally {
      setIsDisabled(false);
    }
  };
  const checkDuplicateInvoice = debounce(async (invoiceNumber) => {
    if (!invoiceNumber) return;

    setCheckingDuplicate(true);
    try {
      const response = await fetch(
        // `https://test.datso.io/check-invoice/${invoiceNumber}`
        `https://test.datso.io/check-invoice/${invoiceNumber}`
      );
      if (response.ok) {
        const data = await response.json();
        setIsDuplicate(data.is_duplicate || false);
      } else {
        console.error("Error checking invoice number:", await response.text());
      }
    } catch (error) {
      console.error("Error checking duplicate invoice:", error);
    } finally {
      setCheckingDuplicate(false);
    }
  }, 500);
  const handleInvoiceNumberChange = (e) => {
    const value = e.target.value;
    setInvoiceNumber(value);
    checkDuplicateInvoice(value);
  };
  const showDialog = (message, severity) => {
    setDialogMessage(message);
    setDialogSeverity(severity || "info");
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    if (dialogSeverity === "success") {
      onClose();
    }
  };

  return (
    <Card
      sx={{
        boxShadow: 3,
        borderRadius: "16px",
        overflow: "hidden",
        backgroundColor: "#F9FAFC",
        maxWidth: "800px",
        margin: "auto",
        position: "relative",
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          color: "#555",
          "&:hover": { color: "#D32F2F" },
        }}
      >
        <CloseIcon />
      </IconButton>
      <CardContent>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#2A3663",
            textAlign: "center",
            marginBottom: 3,
          }}
        >
          Upload Tax Invoice
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Avenue Created ID"
                disabled
                fullWidth
                variant="outlined"
                value={avenueCreatedId}
              />
            </Grid>
            <Grid item xs={12}>
             <TextField
                             label="Invoice Number"
                             value={invoiceNumber}
                             onChange={handleInvoiceNumberChange}
                             fullWidth
                             variant="outlined"
                             helperText={
                               checkingDuplicate
                                 ? "Checking for duplicates..."
                                 : isDuplicate
                                 ? "Invoice number already exists."
                                 : "Invoice number is unique."
                             }
                             error={isDuplicate}
                           />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Vendor Name" // New input field for vendor name
                fullWidth
                variant="outlined"
                value={vendorName}
                onChange={handleVendorNameChange}
              />
            </Grid>

       
            <Grid item xs={12}>
              <Typography>Upload New Invoice </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px dashed",
                  borderColor: "#3f51b5",
                  backgroundColor: "transparent",
                  borderRadius: "8px",
                  padding: 2,
                  cursor: "pointer",
                  textAlign: "center",
                  transition: "background-color 0.3s, border-color 0.3s",
                }}
                onDragOver={(e) => e.preventDefault()}
                onDragLeave={(e) => e.preventDefault()}
                onDrop={(e) => e.preventDefault()}
              >
                <Button
                  variant="text"
                  component="label"
                  sx={{ color: "#3f51b5" }}
                >
                  Drag & Drop File or Click to Upload
                  <input
                    type="file"
                    accept="application/pdf,image/jpeg,image/jpg"
                    hidden
                    onChange={handleFileUpload}
                  />
                </Button>
              </Box>

              {file && <Typography>Uploaded File: {file.name}</Typography>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                multiline
                rows={3}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={isDisabled}
                sx={{
                  backgroundColor: isDisabled ? "#ccc" : "#3f51b5",
                }}
              >
                {isDisabled ? "Processing..." : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            borderRadius: 4,
            boxShadow: 6,
            backgroundColor:
              dialogSeverity === "success" ? "#E8F5E9" : "#FFCDD2",
            color: dialogSeverity === "success" ? "#2E7D32" : "#D32F2F",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontWeight: "bold",
            color: dialogSeverity === "success" ? "#388E3C" : "#D32F2F",
          }}
        >
          {dialogSeverity === "success" ? (
            <CheckCircleIcon fontSize="large" sx={{ color: "#388E3C" }} />
          ) : (
            <ErrorIcon fontSize="large" sx={{ color: "#D32F2F" }} />
          )}
          {dialogSeverity === "success" ? "Success" : "Error"}
        </DialogTitle>
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            sx={{
              backgroundColor:
                dialogSeverity === "success" ? "#388E3C" : "#D32F2F",
              color: "#FFFFFF",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default UploadTaxInvoice;