import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  TextField,
  Autocomplete,
  IconButton,
  Avatar,
  Badge,
  InputAdornment,
  Menu,
  MenuItem,
  Button,
  Tooltip,
} from "@mui/material";
import { Notifications, ArrowBackIos } from "@mui/icons-material";
import LightbulbRoundedIcon from "@mui/icons-material/LightbulbRounded"; // AI Icon
import { useNavigate } from "react-router-dom";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"; 
import HomeIcon from '@mui/icons-material/Home';

export default function AppBarWithSearch({ formType, setFormType }) {
  const [searchValue, setSearchValue] = useState("");
  const [isActive, setIsActive] = useState(false); // Tracks typing or active state
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [role, setRole] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for PIP
  const navigate = useNavigate();

  // Fetch role and email from localStorage
  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    const storedEmail = localStorage.getItem("email");
    const storedRole = localStorage.getItem("role");
    const storedFirstName = localStorage.getItem("first_name");
    const storedLastName = localStorage.getItem("last_name");


    if (storedIsLoggedIn && storedEmail && storedRole) {
      setIsLoggedIn(true);
      setEmail(storedEmail);
      setRole(storedRole);
      setFirstName(storedFirstName);
      setLastName(storedLastName);
    }
  }, []);

  // Handle search input changes
  const handleSearchChange = (event, newValue) => {
    setSearchValue(newValue || "");
    setIsActive(!!newValue); // Set active state if typing starts
    if (newValue) {
      setFormType(newValue); // Set formType based on selection
    }
  };

  // Handle back button click
  const handleBackClick = () => {
    setFormType(""); // Clear formType to return to the search screen
    setSearchValue("");
    setIsActive(false); // Reset active state
  };

  // Handle Avatar Click
  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element for PIP
  };

  // Handle PIP Close
  const handlePIPClose = () => {
    setAnchorEl(null); // Close the PIP
  };

  // Sign-Out Functionality
  const handleSignOut = () => {
    // Clear localStorage and site data
    localStorage.clear();
    if (window.caches) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => caches.delete(cacheName));
      });
    }
    sessionStorage.clear();
    // Navigate to the login screen
    navigate("/");
    window.location.reload(); // Refresh the page to clear state
  };

  // Extract initials from role
  const getInitials = (text) => {
    if (!text) return "DJ";
    const words = text.split(" ");
    return words.map((word) => word[0].toUpperCase()).join("");
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: "linear-gradient(270deg, #2A3663 0%, #B4D6FF 100%)",
        boxShadow: "none",
        padding: "0 16px",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Left Section: Logo and Search Bar */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <img
              onClick={handleBackClick}
              src="https://avenuerealty.in/wp-content/uploads/2022/12/cropped-Avenue-reality-logo.png"
              alt="Avenue Realty Logo"
              style={{
                height: "40px", // Adjust the height of the logo
                marginRight: "8px", // Add spacing between logo and text
                cursor: "pointer",

              }}
            />

<Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "#FFFFFF",
                fontSize: "1.5rem",
                display: "flex",
                alignItems: "baseline",
              }}
            >
              
              <Box
                component="span"
                sx={{
                  fontSize: "1rem", // Smaller font size for Nx
                  marginLeft: "-12px",
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                
                <sup
                  style={{
                    fontSize: "0.9rem", // Smaller font size for the exponent
                    position: "relative",
                    top: "-0.5em", // Position the exponent above the baseline
                    color: "#1e6adc",
                  }}
                >
                  NxT
                </sup>
              </Box>
            </Typography>
          


          {/* Search Bar Positioned Next to Logo */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: 25,
              flex: 1,
            }}
          >
            <Autocomplete
              freeSolo
              options={[
                "Create New Land Deal",
                "View Land Deals",
                "Inventory Management",
                "View Bills",
                "View Vendors",
              ]}
              value={searchValue}
              onInputChange={handleSearchChange}
              renderInput={(params) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: isActive ? "60vh" : "40vh", // Dynamic width adjustment
                    transition: "width 0.3s ease-in-out", // Smooth transition
                  }}
                >
                  {/* Search Bar */}
                  <TextField
                    {...params}
                    placeholder="What do you want to do..."
                    variant="outlined"
                    size="small"
                    sx={{
                      bgcolor: "#FFFFFF",
                      borderRadius: "20px",
                      flex: 1,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "20px",
                        paddingLeft: "10px",
                        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <AutoAwesomeIcon
                            sx={{
                              color: "#757575", // Default icon color
                              transition: "0.3s",
                              "&:hover": {
                                color: "#FFD700", // Glow effect color
                                filter: "drop-shadow(0 0 5px #FFD700)",
                              },
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formType && (
                    <IconButton
                      onClick={handleBackClick}
                      sx={{
                        color: "#FFFFFF",
                        marginRight: "8px",
                      }}
                    >
                      <HomeIcon />
                    </IconButton>
                  )}
                </Box>
              )}
            />
          </Box>
        </Box>

        {/* Right Section: Notifications and Avatar */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <IconButton>
            <Badge badgeContent={3} color="error">
              <Notifications sx={{ color: "#FFFFFF" }} />
            </Badge>
          </IconButton>
          
          <Tooltip title="Profile">
    <Box
      onClick={(event) => setAnchorEl(event.currentTarget)}
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: "8px",
        padding: "6px",
        bgcolor: "rgba(255, 255, 255, 0.1)",
        '&:hover': { bgcolor: "rgba(255, 255, 255, 0.2)" },
      }}
    >
      {/* Truncated Email with '@' Icon */}
      <Avatar
        sx={{
          width: 36,
          height: 36,
          bgcolor: "#2A3663",
          color: "#FFF",
          fontWeight: "bold",
        }}
      >
       
      </Avatar>
      <Typography
        sx={{
          color: "#FFF",
          fontWeight: "bold",
          marginLeft: "8px",
          maxWidth: "250px", // Restrict width
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {firstName || "USER"} {lastName || "USER"}

      </Typography>
    </Box>
  </Tooltip>
        </Box>

        {/* Dropdown Menu for Avatar */}
        <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handlePIPClose}
    sx={{
      "& .MuiPaper-root": {
        borderRadius: "12px",
        minWidth: "250px",
        boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
      },
    }}
  >
          <MenuItem>
            <Typography variant="body1">
              <strong>Name: </strong> {firstName} {lastName}
            </Typography>
          </MenuItem>
          <MenuItem>
            <Typography variant="body1">
              <strong>Role:</strong> {role}
            </Typography>
          </MenuItem>
          <MenuItem>
            <Typography variant="body1">
              <strong>Email:</strong> {email}
            </Typography>
          </MenuItem>
          <MenuItem>
            <Button
              variant="contained"
              color="error"
              onClick={handleSignOut}
              sx={{ textTransform: "none", marginTop: 1 }}
            >
              Sign Out
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}