import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import axios from "axios";

const NewItemForm = ({ onClose, onSave }) => {
  const [itemName, setItemName] = useState("");
  const [itemType, setItemType] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogType, setDialogType] = useState("success");
  const [isLoading, setIsLoading] = useState(false);

  const itemTypeOptions = ["Type A", "Type B", "Type C"];

  const handleSaveDetails = async () => {
    if (!itemName || !itemType) {
      setDialogType("error");
      setDialogMessage("Both Item Name and Item Type are required.");
      setDialogOpen(true);
      return;
    }

    const newItem = { item_name: itemName, item_type: itemType };

    try {
      setIsLoading(true);
      const response = await axios.post("https://test.datso.io/add-masteritem", newItem);

      if (response.data.success) {
        setDialogType("success");
        setDialogMessage(response.data.message || "Item added successfully!");
        setDialogOpen(true);
        if (onSave) onSave(newItem);
      } else {
        throw new Error(response.data.message || "Unknown error occurred.");
      }
    } catch (error) {
      console.error("Error adding item:", error);
      setDialogType("error");
      setDialogMessage(
        error.response?.data?.detail || "An unexpected error occurred. Please try again."
      );
      setDialogOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    if (dialogType === "success") {
      onClose();
    }
  };

  return (
    <Paper
      elevation={10}
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: "70%",
        padding: 4,
        borderRadius: "16px",
        backgroundColor: "#FAFAFA",
        zIndex: 1400,
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 12,
          right: 12,
          color: "#6E7E8B",
          "&:hover": { color: "#D32F2F" },
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          color: "#2A3E4C",
          textAlign: "center",
          marginBottom: 3,
        }}
      >
        Create New Item
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Item Name"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            fullWidth
            value={itemType}
            onChange={(e) => setItemType(e.target.value)}
            displayEmpty
            sx={{ height: 56 }}
          >
            <MenuItem value="" disabled>
              Select Item Type
            </MenuItem>
            {itemTypeOptions.map((type, index) => (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          marginTop: 4,
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            color: "#2A3663",
            borderColor: "#2A3663",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#F0F4FF",
              borderColor: "#3B4A7A",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSaveDetails}
          variant="contained"
          disabled={isLoading}
          sx={{
            backgroundColor: "#2A3663",
            color: "#FFFFFF",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#3B4A7A",
            },
          }}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : "Save Details"}
        </Button>
      </Box>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {dialogType === "success" ? (
            <>
              <CheckCircleIcon color="success" />
              Success
            </>
          ) : (
            <>
              <ErrorIcon color="error" />
              Error
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default NewItemForm;