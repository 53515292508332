import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from "@mui/icons-material/History";

const ReadyForPaymentForm = ({ invoiceData = {}, onClose }) => {
  const {
    avenue_created_invoice_id = "NA",
    supplier_name = "NA",
    supplier_address = "NA",
    supplier_gstin = "NA",
    invoice_date = "NA",
    invoice_number = "NA",
    file_url = "NA",
    items = [],
    amount_to_pay = 0,
    paid_amount = 0,
  } = invoiceData;

  const [paidAmount, setPaidAmount] = useState(paid_amount);
  const [overdueAmount, setOverdueAmount] = useState(amount_to_pay - paid_amount);
  const [remainingAmountToPay, setRemainingAmountToPay] = useState(amount_to_pay);

  const [partialPaymentDialogOpen, setPartialPaymentDialogOpen] = useState(false);
  const [partialPaymentAmount, setPartialPaymentAmount] = useState("");
  const [openPdfDialog, setOpenPdfDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success");

  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const userEmail = localStorage.getItem("email");

  useEffect(() => {
    setRemainingAmountToPay(amount_to_pay - paid_amount);
    setOverdueAmount(amount_to_pay - paid_amount);
  }, [amount_to_pay, paid_amount]);

  const showDialog = (message, severity) => {
    setDialogMessage(message);
    setDialogSeverity(severity);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handlePartialPaymentDialogClose = () => {
    setPartialPaymentDialogOpen(false);
    setPartialPaymentAmount("");
  };

  const fetchTransactionHistory = async (entityType, entityId) => {
    try {
      const response = await fetch(
        `https://test.datso.io/get-transactions/${entityType}/${entityId}`
      );
  
      if (!response.ok) {
        throw new Error("Failed to fetch transaction history.");
      }
  
      const data = await response.json();
      setTransactionHistory(data.transactions);
      setHistoryDialogOpen(true);
    } catch (error) {
      console.error(error);
      showDialog("Error fetching transaction history.", "error");
    }
  };
  

  const handlePartialPaymentSubmit = async () => {
    const parsedAmount = parseFloat(partialPaymentAmount);
  
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      showDialog("Invalid amount entered.", "error");
      return;
    }
  
    if (parsedAmount > overdueAmount) {
      showDialog(
        `Partial payment cannot exceed the overdue amount of ₹${overdueAmount.toFixed(2)}.`,
        "error"
      );
      return;
    }
  
    try {
      const payload = {
        avenue_created_invoice_id,
        payment_status: "PARTIAL",
        paid_amount: parsedAmount, // Send only the current transaction amount
        email: userEmail,
      };
  
      const response = await fetch(
        "https://test.datso.io/update-payment-status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (!response.ok) throw new Error("Failed to update payment status.");
  
      const data = await response.json(); // Use the backend response
  
      // Update state with backend-calculated values
      setPaidAmount(data.paid_amount);
      setOverdueAmount(data.overdue_amount);
      setRemainingAmountToPay(data.overdue_amount);
  
      showDialog(
        `Partial payment of ₹${parsedAmount} recorded successfully.`,
        "success"
      );
      handlePartialPaymentDialogClose();
    } catch (error) {
      console.error(error);
      showDialog("Error updating payment status. Please try again.", "error");
    }
  };
  

  const handleFullPayment = async () => {
    try {
      const payload = {
        avenue_created_invoice_id,
        payment_status: "PAID",
        paid_amount: amount_to_pay,
        overdue_amount: 0,
        email: userEmail,
      };

      const response = await fetch(
        "https://test.datso.io/update-payment-status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) throw new Error("Failed to update payment status.");

      setPaidAmount(amount_to_pay);
      setOverdueAmount(0);
      setRemainingAmountToPay(0);

      showDialog("Full payment recorded successfully.", "success");
    } catch (error) {
      console.error(error);
      showDialog("Error updating payment status. Please try again.", "error");
    }
  };
  const handlePdfDialogOpen = () => {
    setOpenPdfDialog(true);
  };
  const handlePdfDialogClose = () => {
    setOpenPdfDialog(false);
  };
  return (
    <Box sx={{ padding: 4, backgroundColor: "#F9F9F9", minHeight: "100vh" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#2A3663" }}>
          Invoice Overview #{avenue_created_invoice_id}
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>       
          <IconButton onClick={handlePdfDialogOpen} color="primary">
            <VisibilityIcon />
          </IconButton>
          <IconButton
            onClick={() => fetchTransactionHistory("INVOICE", avenue_created_invoice_id)}
            color="primary"
          >
            <HistoryIcon />
          </IconButton>
          <Button
            variant="contained"
            onClick={() => setPartialPaymentDialogOpen(true)}
            disabled={overdueAmount <= 0}
          >
            Pay Partially
          </Button>
          <Button
            variant="contained"
            onClick={handleFullPayment}
            disabled={remainingAmountToPay <= 0}
          >
            Pay Full
          </Button>
          <IconButton
            onClick={onClose}
            sx={{
              color: "#555",
              backgroundColor: "#f0f0f0",
              "&:hover": { backgroundColor: "#d32f2f", color: "#fff" },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", color: "#555" }}
          >
            Vendor Name: {supplier_name}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "#555" }}>
            Vendor Address: {supplier_address}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "#555" }}>
            GSTIN: {supplier_gstin}
          </Typography>
        </Box>
        <Box textAlign="right">
          <Typography variant="subtitle1" sx={{ color: "#555" }}>
            Date: {invoice_date}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "#555" }}>
            Invoice Number: {invoice_number}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "#555" }}>
            Payment Status: {invoiceData.payment_status}
          </Typography>
          
        </Box>
      </Box>

      <Box sx={{ marginBottom: 3 }}>
  <Typography variant="subtitle1" sx={{ color: "#555" }}>
    Total Payable Amount: ₹{amount_to_pay}
  </Typography>
  <Typography variant="subtitle1" sx={{ color: "#555" }}>
    Amount Paid: ₹{paidAmount.toFixed(2)}
  </Typography>
  <Typography variant="subtitle1" sx={{ color: "#555" }}>
    Outstanding Amount: ₹{overdueAmount.toFixed(2)}
  </Typography>
</Box>


      {items.length === 0 ? (
        <Typography>No items available for this invoice.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#2A3663" }}>
              <TableRow>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                  Item Description
                </TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Code</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Quantity</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Unit Price</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Total Price</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.description || "N/A"}</TableCell>
                  <TableCell>{item.code || "N/A"}</TableCell>
                  <TableCell>{item.quantity || 0}</TableCell>
                  <TableCell>₹{parseFloat(item.unit_price || 0).toFixed(2)}</TableCell>
                  <TableCell>₹{parseFloat(item.taxable_value || 0).toFixed(2)}</TableCell>
                
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      
      <Dialog open={partialPaymentDialogOpen} onClose={handlePartialPaymentDialogClose}>
        <DialogTitle>Enter Partial Payment Amount</DialogTitle>
        <DialogContent>
          <TextField
            label="Amount"
            type="number"
            fullWidth
            value={partialPaymentAmount}
            onChange={(e) => setPartialPaymentAmount(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePartialPaymentDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePartialPaymentSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPdfDialog}
        onClose={handlePdfDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Invoice PDF</DialogTitle>
        <DialogContent>
          <iframe
            src={file_url}
            width="100%"
            height="700px"
            style={{ border: "none" }}
            title="Invoice PDF"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePdfDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            backgroundColor:
              dialogSeverity === "success" ? "#e1fcd4" : "#FDECEA",
          },
        }}
      >
        <DialogTitle>
          {dialogSeverity === "success" ? "Success" : "Error"}
        </DialogTitle>
        <DialogContent>{dialogMessage}</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>


     {/* Transaction History Dialog */}
<Dialog
  open={historyDialogOpen}
  onClose={() => setHistoryDialogOpen(false)}
  PaperProps={{
    sx: {
      width: "80%", // Adjust the width as needed
      maxWidth: "80%", // Prevent it from exceeding the screen width
      padding: "20px", // Optional: Add padding for better spacing
    },
  }}
>
  <DialogTitle>Transaction History</DialogTitle>
  <DialogContent>
    {transactionHistory.length === 0 ? (
      <Typography>No transaction history available.</Typography>
    ) : (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>Transaction ID</TableCell> */}
              <TableCell>Type</TableCell>
              <TableCell>Transaction Amount</TableCell>
              <TableCell>Total Amount</TableCell>
              <TableCell>Paid Amount</TableCell>
              <TableCell>Avenue Invoice ID</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionHistory.map((transaction, index) => (
              <TableRow key={index}>
                {/* <TableCell>{transaction.transaction_id}</TableCell> */}
                <TableCell>{transaction.transaction_type}</TableCell>
                <TableCell>₹{transaction.transaction_amount.toFixed(2)}</TableCell>
                <TableCell>₹{transaction.total_amount.toFixed(2)}</TableCell>
                <TableCell>₹{transaction.paid_amount.toFixed(2)}</TableCell>
                <TableCell>{transaction.avenue_created_invoice_id}</TableCell>
                <TableCell>
                  {Object.entries(transaction.details).map(([key, value]) => (
                    <Typography key={key}>
                      {key}: {value}
                    </Typography>
                  ))}
                </TableCell>
                <TableCell>{new Date(transaction.timestamp).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setHistoryDialogOpen(false)}>Close</Button>
  </DialogActions>
</Dialog>

    </Box>
  );
};

export default ReadyForPaymentForm;