import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TextField,
  InputAdornment,
  Tooltip,
  TablePagination,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditVendorForm from "./EditVendorForm";

const VendorDetails = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedVendor, setSelectedVendor] = useState(null);

  const fetchVendors = async () => {
    try {
      const response = await fetch("https://test.datso.io/get-all-vendors");
      if (response.ok) {
        const data = await response.json();
        setVendors(data.vendors || []);
      } else {
        setError("Failed to fetch vendor data.");
      }
    } catch (error) {
      setError("Error fetching vendors: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  const handleSearch = (vendor) => {
    const query = searchQuery.toLowerCase();
    return (
      vendor.vendor_id?.toLowerCase().includes(query) ||
      vendor.vendor_name?.toLowerCase().includes(query) ||
      vendor.vendor_gst_number?.toLowerCase().includes(query) ||
      vendor.vendor_bank_account_number?.toLowerCase().includes(query) ||
      vendor.vendor_bank_name?.toLowerCase().includes(query) ||
      vendor.vendor_bank_ifsc_code?.toLowerCase().includes(query)
    );
  };

  const filteredVendors = vendors.filter(handleSearch);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (vendor) => {
    setSelectedVendor(vendor);
  };

  const handleVendorUpdated = () => {
    setSelectedVendor(null);
    fetchVendors();
  };

  return (
    <Box sx={{ padding: 2, maxWidth: "100%", margin: "1%", backgroundColor: "#F9F9F9", borderRadius: "8px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 3 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "black" }}>
          Vendors Details List
        </Typography>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", paddingTop: 5 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: "#2A3663", color: "white" }}>
                <TableRow>
                  <TableCell sx={{ color: "white", cursor: "pointer" }}>Vendor ID</TableCell>
                  <TableCell sx={{ color: "white", cursor: "pointer" }}>Vendor Display Name</TableCell>
                  <TableCell sx={{ color: "white", cursor: "pointer" }}>GST Number</TableCell>
                  <TableCell sx={{ color: "white", cursor: "pointer" }}>Bank Name</TableCell>
                  <TableCell sx={{ color: "white", cursor: "pointer" }}>Account Number</TableCell>
                  <TableCell sx={{ color: "white", cursor: "pointer" }}>IFSC Code</TableCell>
                  <TableCell sx={{ color: "white", cursor: "pointer" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredVendors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((vendor) => (
                  <TableRow key={vendor.vendor_id}>
                    <TableCell>{vendor.vendor_id}</TableCell>
                    <TableCell>{vendor.vendor_display_name}</TableCell>
                    <TableCell>{vendor.vendor_gst_number}</TableCell>
                    <TableCell>{vendor.vendor_bank_name}</TableCell>
                    <TableCell>{vendor.vendor_bank_account_number}</TableCell>
                    <TableCell>{vendor.vendor_bank_ifsc_code}</TableCell>
                    <TableCell>
                      <Tooltip title="Edit Vendor">
                        <IconButton onClick={() => handleEditClick(vendor)} color="primary">
                          <BorderColorIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={filteredVendors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      {selectedVendor && (
        <EditVendorForm
          vendorData={selectedVendor}
          onClose={() => setSelectedVendor(null)}
          onVendorCreated={handleVendorUpdated}
        />
      )}
    </Box>
  );
};

export default VendorDetails;
