import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Dialog,
  Tooltip,
  DialogActions,
  DialogContent,
  DialogTitle,
  TablePagination,
  Select,
  MenuItem,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadInvoice from "./UploadInvoice";
import ReadyForReview from "./ReadyForReviewForm";
import ReadyForPayment from "./ReadyForPaymentForm";
import PaidBillForm from "./PaidBillForm";
import ReviewedForm from "./ReviewedForm";
import { InputAdornment } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import SearchIcon from "@mui/icons-material/Search";
import ProformaInvoice from "./ProformaInvoice";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import UploadTaxInvoice from "./UploadTaxInvoice";
import AddIcon from '@mui/icons-material/Add';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';


const tabPermissions = {
  admin: [
    "Review Invoice",
    "Manage Inventory",
    "Make Payments",
    // "Processed Invoices",
    "All Invoices",
  ],
  Stock_Management_Team: ["Manage Inventory"],
  Finance_Team: ["Make Payments"],
  Backend_Team: ["Review Invoice", "All Invoices",],
};

const statusColors = {
  READY_FOR_REVIEW: " #ffcc99",
  REVIEWED: " #ccccff",
  READY_FOR_PAYMENT: "#ffffb3",
  PROCESSED: "#ccffcc",
  REJECTED: " #ffad99",
};

const statusTextColor = {
  READY_FOR_REVIEW: " #e67300",
  REVIEWED: "#0000cc",
  READY_FOR_PAYMENT: "#e6e600",
  PROCESSED: "#00cc00",
  REJECTED: " #cc2900",
};

const statusBorderColor = {
  READY_FOR_REVIEW: "  #e67300",
  REVIEWED: "#0000cc",
  READY_FOR_PAYMENT: "#e6e600",
  PROCESSED: "#00cc00",
  REJECTED: "#cc2900",
};

const BillsList = () => {
  const [activeTab, setActiveTab] = useState("All Invoices");
  const [tabsToShow, setTabsToShow] = useState([]);
  const [roles, setRoles] = useState([]);
  const [bills, setBills] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [billDetails, setBillDetails] = useState(null);
  const [formView, setFormView] = useState("");
  const [isFormOpen, setIsFormOpen] = useState(true);
  const [openPdfDialog, setOpenPdfDialog] = useState(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [sortField, setSortField] = useState("uploaded_at_ist");
  const [sortOrder, setSortOrder] = useState("desc");
  const [showProformaInvoice, setShowProformaInvoice] = useState(false);
  const [editRow, setEditRow] = useState(null); // Track which row is being edited
  const [showTaxInvoiceDialog, setShowTaxInvoiceDialog] = useState(false);
  const [selectedInvoiceForTax, setSelectedInvoiceForTax] = useState(null);
  const [expandedProforma, setExpandedProforma] = useState(null); // Tracks which Proforma Invoice is expanded
  const [childInvoices, setChildInvoices] = useState({}); // Stores child invoices for each expanded Proforma Invoice
  const parentColumnCount = 10;
  const [invoices, setInvoices] = useState([]); // Stores parent-child invoices
  const [expandedInvoices, setExpandedInvoices] = useState({}); // Tracks expanded parent invoices


  const [dialogContent, setDialogContent] = useState({
    title: "",
    message: "",
  });

  const fetchRoles = async () => {
    try {
      const email = localStorage.getItem("email");
      const response = await fetch(
        `https://test.datso.io/roles?email=${email}`
        // `https://test.datso.io/roles?email=${email}`
      );
      const data = await response.json();
      // Update localStorage
      localStorage.setItem("roles", JSON.stringify(data.role));
      setRoles(data.role);

      // Determine tabs based on roles
      const availableTabs = new Set();
      data.role.forEach((role) => {
        const roleTabs = tabPermissions[role] || [];
        roleTabs.forEach((tab) => availableTabs.add(tab));
      });

      setTabsToShow(Array.from(availableTabs));

      // Set default active tab
      const defaultTab = data.role.includes("admin")
        ? "All Invoices"
        : data.role.includes("Stock_Management_Team")
          ? "Manage Inventory"
          : data.role.includes("Finance_Team")
            ? "Make Payments"
            : "Review Invoice";
      setActiveTab(defaultTab);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };
  const handleOpenTaxInvoiceDialog = (bill) => {
    setSelectedInvoiceForTax(bill); // Set the selected bill data
    setShowTaxInvoiceDialog(true); // Open the dialog
  };


  const handleCloseTaxInvoiceDialog = () => {
    setShowTaxInvoiceDialog(false); // Close the dialog
    setSelectedInvoiceForTax(null); // Reset selected invoice data
  };
  
  
  

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleStatusChange = async (id, newStatus = "READY_FOR_REVIEW") => {
    try {
      const response = await fetch("https://test.datso.io/update-invoicestatus", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          avenue_created_invoice_id: id,
          new_status: newStatus,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to update status");
      }
  
      const data = await response.json();
  
      // Update the local state to reflect the change dynamically
      setBills((prevBills) =>
        prevBills.map((bill) =>
          bill.avenue_created_invoice_id === id
            ? { ...bill, bill_status: newStatus }
            : bill
        )
      );
      console.log(`Status updated successfully to ${newStatus}`);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  

  // Add useEffect to watch changes and dynamically update the UI
  useEffect(() => {
    const fetchBills = async () => {
      try {
        const response = await fetch("https://test.datso.io/get-all-invoices");
        if (!response.ok) throw new Error("Failed to fetch bills.");
        const data = await response.json();
        setBills(data.invoices || []);
        console.log("[DEBUG] Bills updated dynamically via useEffect.");
      } catch (error) {
        console.error("[ERROR] Failed to fetch bills in useEffect:", error);
      }
    };

    fetchBills();
  }, []); // Dependency array empty to fetch on initial render

  const handleView = async (bill, activeTab) => {
    try {
      const avenueInvoiceId = bill.avenue_created_invoice_id;
      if (!avenueInvoiceId) {
        console.error("avenue_created_invoice_id is missing.");
        setDialogContent({
          title: "Missing Invoice ID",
          message: "Invoice ID is missing. Please provide a valid invoice.",
        });
        setOpenDialog(true);
        return;
      }
      console.log(  `Fetching details for avenue_created_invoice_id: ${avenueInvoiceId}`);
      // const apiUrl = `https://test.datso.io/get-invoice/${encodeURIComponent(
      const apiUrl = `https://test.datso.io/get-invoice/${encodeURIComponent(
        avenueInvoiceId
      )}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const result = await response.json();

      if (!response.ok || !result.invoice) {
        throw new Error(result.error || "Failed to fetch bill details.");
      }

      console.log("Fetched bill details:", result);

      let viewType = "";
      const status = bill.bill_status;

      if (activeTab === "Manage Inventory" && status === "REVIEWED") {
        viewType = "ReviewedForm";
      } else if (activeTab === "Make Payments") {
        viewType = "ReadyForPayment";
      } else if (status === "READY_FOR_REVIEW") {
        viewType = "ReadyForReview";
      } else if (status === "PROCESSED") {
        viewType = "PaidBillForm";
      }

      setBillDetails(result.invoice); // Update to access the invoice data
      setFormView(viewType);
    } catch (error) {
      console.error("Error in handleView:", error);
      setDialogContent({
        title: "Error Loading Bill",
        message: `Failed to load bill details: ${error.message}`,
      });
      setOpenDialog(true);
    }
  };
  const handleProformaView = async (bill) => {
    setShowProformaInvoice(true);
    try {
      const avenueInvoiceId = bill.avenue_created_invoice_id;

      if (!avenueInvoiceId) {
        console.error("avenue_created_invoice_id is missing.");
        setDialogContent({
          title: "Missing Invoice ID",
          message: "Invoice ID is missing. Please provide a valid invoice.",
        });
        setOpenDialog(true);
        return;
      }

      console.log(
        `Fetching details for avenue_created_invoice_id: ${avenueInvoiceId}`
      );

      // const apiUrl = `https://test.datso.io/get-invoice/${encodeURIComponent(
      const apiUrl = `https://test.datso.io/get-invoice/${encodeURIComponent(
        avenueInvoiceId
      )}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const result = await response.json();

      if (!response.ok || !result.invoice) {
        throw new Error(result.error || "Failed to fetch bill details.");
      }

      console.log("Fetched bill details:", result);

      let viewType = "";
      const status = bill.bill_status;

      if (activeTab === "Manage Inventory" && status === "REVIEWED") {
        viewType = "ReviewedForm";
      } else if (activeTab === "Make Payments") {
        viewType = "ReadyForPayment";
      } else if (status === "READY_FOR_REVIEW") {
        viewType = "ReadyForReview";
      } else if (status === "PROCESSED") {
        viewType = "PaidBillForm";
      }

      setBillDetails(result.invoice); // Update to access the invoice data
      setFormView(viewType);
    } catch (error) {
      console.error("Error in handleView:", error);
      setDialogContent({
        title: "Error Loading Bill",
        message: `Failed to load bill details: ${error.message}`,
      });
      setOpenDialog(true);
    }
  };
  const handleSortChange = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortField(field);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  const fetchBills = async () => {
    try {
      const response = await fetch("https://test.datso.io/get-all-invoices");
      if (!response.ok) throw new Error("Failed to fetch bills from API.");
      const result = await response.json();

      console.log("Fetched bills response:", result); // Debug log

      // Check if the result is an array or wrapped in an object
      if (Array.isArray(result.invoices)) {
        setBills(result.invoices);
      } else if (Array.isArray(result)) {
        setBills(result);
      } else {
        console.error("Unexpected API response format:", result);
        setBills([]); // Fallback to an empty array
      }
    } catch (err) {
      console.error("Error fetching bills:", err);
      setBills([]); // Fallback to an empty array in case of error
    }
  };
  const handleExpandProforma = async (proformaId) => {
    try {
        if (expandedProforma === proformaId) {
            // Collapse if already expanded
            setExpandedProforma(null);
            return;
        }

        // Check if child invoices for this Proforma ID are already cached
        if (childInvoices[proformaId]) {
            setExpandedProforma(proformaId); // Expand from cache
            return;
        }

        // Fetch child invoices from backend
        const response = await fetch(
            `https://test.datso.io/get-child-invoices/${proformaId}`
        );

        if (!response.ok) {
            throw new Error('Failed to fetch child invoices');
        }

        const data = await response.json();

        // Update state with fetched child invoices
        setChildInvoices((prev) => ({
            ...prev,
            [proformaId]: data.child_invoices, // Store child invoices for this Proforma ID
        }));

        setExpandedProforma(proformaId); // Expand the Proforma
    } catch (error) {
        console.error('Error fetching child invoices:', error);
        alert('Error fetching child invoices. Please try again.');
    }
};

  
  
  
  useEffect(() => {
    fetchBills();
  }, []);

  const tabStatusMap = {
    "Review Invoice": "READY_FOR_REVIEW",
    "Manage Inventory": "REVIEWED",
    "Make Payments": "READY_FOR_PAYMENT",
    "Processed Invoices": "PROCESSED",
    "All Invoices": null,
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    fetchBills();
  };

  const handlePdfDialogOpen = () => {
    setOpenPdfDialog(true);
  };

  const handlePdfDialogClose = () => {
    setOpenPdfDialog(false);
  };
  const handleToggleInvoice = (parentId) => {
    setExpandedInvoices((prev) => ({
      ...prev,
      [parentId]: !prev[parentId], // Toggle the expanded state
    }));
  };
  

  const filteredBills = bills
    .filter((bill) => {
      const selectedStatus = tabStatusMap[activeTab];
      const isReadyForPaymentTab =
        activeTab === "Make Payments" &&
        (bill.bill_status === "READY_FOR_PAYMENT" ||
          bill.bill_status === "REVIEWED");

      const matchesTab =
        selectedStatus === null ||
        bill.bill_status === selectedStatus ||
        isReadyForPaymentTab;

      const matchesSearch =
        String(bill.json_invoice_number || "")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(bill.supplier_name || "")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(bill.products_longhand_list || "")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(bill.avenue_created_invoice_id || "")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(bill.uploaded_at_ist_human || "")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(bill.total_bill_amount || "")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(bill.bill_status || "")
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

      return matchesTab && matchesSearch;
    })
    .sort((a, b) => {
      const dateA = new Date(a.uploaded_at_ist_human);
      const dateB = new Date(b.uploaded_at_ist_human);
      return dateB - dateA; // Sort by Uploaded Date (latest first)
    })
    .sort((a, b) => {
      if (sortField) {
        const aField = a[sortField];
        const bField = b[sortField];
        if (aField < bField) return sortOrder === "asc" ? -1 : 1;
        if (aField > bField) return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const paginatedBills = filteredBills.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );
  const navigate = useNavigate();
  const handleCloseForm = () => {
    setFormView(""); // Clear the form view
    setBillDetails(null); // Clear the bill details
  };

  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: "#F4F6F9",
        minHeight: "96%",
        marginLeft: "14px",
        marginTop: "-1%",
      }}
    >
      {/* Header */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#2C3E50" }}>
          Invoices
        </Typography>
        <Button
          variant="contained"
          startIcon={<UploadFileIcon />}
          onClick={() => setShowUploadForm(true)}
          sx={{
            backgroundColor: "#2A3663",
            "&:hover": { backgroundColor: "#1E2A48" },
          }}
        >
          Upload Invoice
        </Button>
      </Box>

      {/* Tabs and Search */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        {/* Tabs */}
        <Tabs value={activeTab} onChange={handleTabChange}>
          {tabsToShow.map((tab) => (
            <Tab key={tab} value={tab} label={tab} />
          ))}
        </Tabs>

        <TextField
          placeholder="Search Invoice No & Supplier Name"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            width: "350px",
            backgroundColor: "#fffff",
            borderRadius: "25px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "25px",
              height: "36px", // Set a reduced height
              padding: "0", // Remove extra padding
              "& input": {
                padding: "8px 12px", // Control the inner padding
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <SearchIcon sx={{ color: "#2C3E50" }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ "& .MuiTableRow-root": { minHeight: "3rem" },    borderCollapse: "collapse",
 }}>
          <TableHead sx={{ backgroundColor: "#2A3663" }}>
            <TableRow  >
            <TableCell sx={{ width: "50px", textAlign: "center" }}></TableCell>
              <TableCell
                sx={{ color: "white", cursor: "pointer" }}
                onClick={() => handleSortChange("avenue_created_invoice_id")}
              >
                Avenue ID{" "}
                {sortField === "avenue_created_invoice_id"
                  ? sortOrder === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>
              <TableCell
                sx={{ color: "white", cursor: "pointer" }}
                onClick={() => handleSortChange("supplier_name")}
              >
                Supplier Name{" "}
                {sortField === "supplier_name"
                  ? sortOrder === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>
              <TableCell
                sx={{ color: "white", cursor: "pointer" }}
                onClick={() => handleSortChange("json_invoice_number")}
              >
                Invoice Number{" "}
                {sortField === "json_invoice_number"
                  ? sortOrder === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>
              <TableCell
                sx={{ color: "white", cursor: "pointer" }}
                onClick={() => handleSortChange("uploaded_at_ist_readable")}
              >
                Uploaded Date{" "}
                {sortField === "uploaded_at_ist_readable"
                  ? sortOrder === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>
              <TableCell sx={{ color: "white" }}>Invoice Type</TableCell>

              <TableCell
                sx={{ color: "white", cursor: "pointer" }}
                onClick={() => handleSortChange("total_bill_amount")}
              >
                Total Amount{" "}
                {sortField === "total_bill_amount"
                  ? sortOrder === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>
              <TableCell
                sx={{ color: "white", cursor: "pointer" }}
                onClick={() => handleSortChange("products_longhand_list")}
              >
                Products{" "}
                {sortField === "products_longhand_list"
                  ? sortOrder === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>

              <TableCell
                sx={{ color: "white", cursor: "pointer" }}
                onClick={() => handleSortChange("bill_status")}
              >
                Status{" "}
                {sortField === "bill_status"
                  ? sortOrder === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>
              <TableCell sx={{ color: "white" }}>Invoice</TableCell>
              <TableCell sx={{ color: "white" }}>Edit Status</TableCell>
              
              
              <TableCell sx={{ color: "white" }}> Upload</TableCell>
              
            </TableRow>
          </TableHead>


          <TableBody>
            {paginatedBills.map((bill) => (
              <React.Fragment key={bill.avenue_created_invoice_id}>
              <TableRow key={bill.avenue_created_invoice_id}>
                
              <TableCell>
                    {bill.children.length > 0 && (
                      <IconButton onClick={() => handleToggleInvoice(bill.avenue_created_invoice_id)}>

                        {expandedInvoices[bill.avenue_created_invoice_id] ? (
                          <ArrowBackIcon />
                        ) : (
                          <AddIcon />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                <Tooltip
                  title={bill.avenue_created_invoice_id}
                  placement="auto"
                >
                  
                  

                  

                  <TableCell
                    sx={{
                      maxWidth: "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                      color: "#1565C0",
                      textDecoration: "underline",
                    
                    }}
                    onClick={() => handleView(bill, activeTab)}
                  >
                    {bill.avenue_created_invoice_id}
                  </TableCell>
                </Tooltip>

                <Tooltip title={bill.supplier_name} placement="auto">
                  <TableCell
                    sx={{
                      maxWidth: "200px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                  >
                    {bill.supplier_name}
                  </TableCell>
                </Tooltip>
                <Tooltip title={bill.json_invoice_number} placement="auto">
                  <TableCell
                    sx={{
                      maxWidth: "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                  >
                    {bill.json_invoice_number}
                  </TableCell>
                </Tooltip>

                <Tooltip title={bill.uploaded_at_ist_human} placement="auto">
                  <TableCell
                    sx={{
                      maxWidth: "175px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                  >
                    {bill.uploaded_at_ist_human}
                  </TableCell>
                </Tooltip>

                <TableCell>{bill.invoice_type}</TableCell>

                <TableCell>
                  {bill.total_bill_amount
                    ? `₹${bill.total_bill_amount}`
                    : bill.total_bill_amount
                      ? `₹${bill.total_bill_amount}`
                      : "N/A"}
                </TableCell>

                <Tooltip title={bill.products_longhand_list} placement="auto">
                  <TableCell
                    sx={{
                      maxWidth: "200px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                  >
                    {bill.products_longhand_list}
                  </TableCell>
                </Tooltip>

                <Tooltip title={bill.bill_status} placement="auto">
                <TableCell>
                  <Box
                    sx={{
                      textAlign: "center",
                      padding: "4px",
                      borderRadius: "4px",
                      fontWeight: "bold",
                      backgroundColor: statusColors[bill.bill_status] || "#F0F0F0",
                      color: statusTextColor[bill.bill_status] || "#000000",
                      width: "200px",
                      height: "30px",
                      border: `1px solid ${statusBorderColor[bill.bill_status] || "#D3D3D3"}`,
                    }}
                  >
                    {bill.bill_status}
                  </Box>
                </TableCell>
                



                </Tooltip>
                <Tooltip>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      const fileUrl = bill.file_url; // Fetch the file URL dynamically
                      if (fileUrl) {
                        setSelectedPdfUrl(fileUrl); // Set the selected PDF URL
                        setOpenPdfDialog(true); // Open the PDF dialog
                      } else {
                        console.error("No PDF URL found for this bill.");
                        setDialogContent({
                          title: "Error",
                          message: "No file available for this invoice.",
                        });
                        setOpenDialog(true);
                      }
                    }}
                    color="primary"
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
                </Tooltip>
                <TableCell>
                  {roles.includes("admin") || roles.includes("Backend_Team") ? (
                    <Tooltip title="Set Status to READY_FOR_REVIEW">
                      <IconButton
                        onClick={() => handleStatusChange(bill.avenue_created_invoice_id, "READY_FOR_REVIEW")}
                        color="primary"
                      >
                        <BorderColorIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="You do not have permission to edit this status">
                      <span>
                        <IconButton disabled color="primary">
                          <BorderColorIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </TableCell>
               
                {/* <TableCell>
                  {roles.includes("admin") || roles.includes("Backend_Team") ? (
                    <IconButton
                      onClick={() => setEditRow(bill.avenue_created_invoice_id)}
                      color="primary"
                    >
                      <BorderColorIcon />
                    </IconButton>
                  ) : (
                    <Tooltip title="You do not have permission to edit this status">
                      <span>
                        <IconButton disabled color="primary">
                          <BorderColorIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </TableCell> */}
                <TableCell>
                  {bill.invoice_type === "Proforma Invoice" ? (
                    <Tooltip title="Upload Tax Invoice">
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => handleOpenTaxInvoiceDialog(bill)}
                        disabled={bill.bill_status === "READY_FOR_REVIEW"}
                      >
                        <UploadFileIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Typography variant="caption" color="textSecondary">
                    
                    </Typography>
                  )}
                </TableCell>
                




              </TableRow>


{expandedInvoices[bill.avenue_created_invoice_id] &&
  bill.children
    .filter((child) => {
      // Exclude bills with status READY_FOR_REVIEW in specific tabs
      if (
        (activeTab === "Manage Inventory" || activeTab === "Make Payments") &&
        child.bill_status === "READY_FOR_REVIEW"
      ) {
        return false;
      }
      return true; // Include all other bills
    })
    .map((child) => (
      <TableRow key={child.avenue_created_invoice_id}>
        <TableCell>
          <SubdirectoryArrowRightIcon />
        </TableCell>
        <TableCell
          sx={{
            maxWidth: "150px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
            color: "#1565C0",
            textDecoration: "underline",
            paddingLeft: "20px",
          }}
          onClick={() => handleView(child, activeTab)}
        >
          <Typography
            sx={{
              marginRight: 1,
              fontFamily: "monospace",
              fontSize: "16px",
              color: "#000", // Optional: color of the icon
            }}
          >
            {child.level > 0 && "|_"}
          </Typography>
          {child.avenue_created_invoice_id}
        </TableCell>
        <TableCell
          sx={{
            maxWidth: "200px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
        >
          {child.supplier_name}
        </TableCell>
        <TableCell>{child.json_invoice_number}</TableCell>
        <TableCell>{child.uploaded_at_ist_human}</TableCell>
        <TableCell>{child.invoice_type}</TableCell>
        <TableCell>{`₹${child.total_bill_amount}`}</TableCell>
        <Tooltip title={child.products_longhand_list} placement="auto">
          <TableCell
            sx={{
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {child.products_longhand_list}
          </TableCell>
        </Tooltip>
        <TableCell>
          <Box
            sx={{
              textAlign: "center",
              padding: "4px",
              borderRadius: "4px",
              fontWeight: "bold",
              backgroundColor: statusColors[child.bill_status] || "#F0F0F0",
              color: statusTextColor[child.bill_status] || "#000000",
              width: "200px",
              height: "30px",
              border: `1px solid ${
                statusBorderColor[child.bill_status] || "#D3D3D3"
              }`,
            }}
          >
            {child.bill_status}
          </Box>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => handleView(child)} color="primary">
            <VisibilityIcon />
          </IconButton>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    ))}











              {expandedProforma === bill.avenue_created_invoice_id && (
  <React.Fragment>
    {childInvoices[bill.avenue_created_invoice_id]?.filter((child) => {
      const selectedStatus = tabStatusMap[activeTab];
      const isReadyForPaymentTab =
        activeTab === "Make Payments" &&
        (child.bill_status === "READY_FOR_PAYMENT" || child.bill_status === "REVIEWED");

      const matchesTab =
        selectedStatus === null ||
        child.bill_status === selectedStatus ||
        isReadyForPaymentTab;

      return matchesTab;
    }).length > 0 ? (
      childInvoices[bill.avenue_created_invoice_id]
        .filter((child) => {
          const selectedStatus = tabStatusMap[activeTab];
          const isReadyForPaymentTab =
            activeTab === "Make Payments" &&
            (child.bill_status === "READY_FOR_PAYMENT" || child.bill_status === "REVIEWED");

          const matchesTab =
            selectedStatus === null ||
            child.bill_status === selectedStatus ||
            isReadyForPaymentTab;

          return matchesTab;
        })
        .map((child) => (
          <TableRow sx={{
            height: "56px", // Match parent row height
            "&:hover": { backgroundColor: "#f9f9f9" }, // Optional: Highlight on hover
          }}key={child.avenue_created_invoice_id}>
            
            <TableCell>
              <Typography><SubdirectoryArrowRightIcon
                  sx={{
                    
                    fontSize: "20px", // Adjust icon size
                    color: "#2A3663", // Optional: change icon color
                    paddingLeft: "1px",
                    alignContent:"right",
                  }}
                />
                </Typography>
                </TableCell>
            <TableCell
              sx={{
                maxWidth: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
                color: "#1565C0",
                textDecoration: "underline",
                paddingLeft: "20px",
              }}
              onClick={() => handleView(child, activeTab)}
            >
              <Typography
    sx={{
      marginRight: 1,
      fontFamily: "monospace",
      fontSize: "16px",
      color: "#000", // Optional: color of the icon
    }}
  >
    {child.level > 0 && "|_"}
  </Typography>

              {child.avenue_created_invoice_id}
            </TableCell>

            <TableCell sx={{
                      maxWidth: "200px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}>{child.supplier_name}</TableCell>
            <TableCell>{child.json_invoice_number}</TableCell>
            <TableCell>{child.uploaded_at_ist_human}</TableCell>
            <TableCell>{child.invoice_type}</TableCell>
            <TableCell>{`₹${child.total_bill_amount}`}</TableCell>
            <Tooltip title={child.products_longhand_list} placement="auto">
                  <TableCell
                    sx={{
                      maxWidth: "200px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                  >
                    {child.products_longhand_list}
                  </TableCell>
                </Tooltip>
            <TableCell>
              <Box
                sx={{
                  textAlign: "center",
                  padding: "4px",
                  borderRadius: "4px",
                  fontWeight: "bold",
                  backgroundColor:
                    statusColors[child.bill_status] || "#F0F0F0",
                  color: statusTextColor[child.bill_status] || "#000000",
                  width: "200px",
                  height: "30px",
                  border: `1px solid ${
                    statusBorderColor[child.bill_status] || "#D3D3D3"
                  }`,
                }}
              >
                {child.bill_status}
              </Box>
            </TableCell>
            <TableCell>
              <IconButton
                onClick={() => handleView(child)}
                color="primary"
              >
                <VisibilityIcon />
              </IconButton>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            {/* <TableCell colSpan={2}>
              <Tooltip title="Upload Tax Invoice">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => handleOpenTaxInvoiceDialog(child)}
                >
                  <UploadFileIcon />
                </IconButton>
              </Tooltip>
            </TableCell> */}
          </TableRow>
        ))
    ) : (
      <TableRow>
        <TableCell colSpan={11} align="center">
          No child invoices match the selected criteria.
        </TableCell>
      </TableRow>
    )}
  </React.Fragment>
)}

                              </React.Fragment>
                              
       


            ))}
          </TableBody>
        </Table>
        <Dialog
                  open={openPdfDialog}
                  onClose={handlePdfDialogClose}
                  fullWidth
                  maxWidth="lg"
                >
                  <DialogTitle>Invoice PDF</DialogTitle>
                  <DialogContent>
                    {selectedPdfUrl ? (
                      <iframe
                        src={selectedPdfUrl}
                        width="100%"
                        height="700px"
                        style={{ border: "none" }}
                        title="Invoice PDF"
                      />
                    ) : (
                      <Typography>No PDF available</Typography>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenPdfDialog(false);
                        setSelectedPdfUrl(""); // Clear the PDF URL when closing
                      }}
                      color="primary"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>


        <TablePagination
          component="div"
          count={filteredBills.length}
          page={currentPage}
          onPageChange={handleChangePage}
          rowsPerPage={itemsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* Conditional Form Rendering */}
      {formView && billDetails && (
        <Box
          sx={{
            position: "fixed",
            top: "9%",
            left: "50%",
            transform: "translate(-50%, 0)",
            width: "98%",
            height: "98%",
            overflowY: "auto",
            backgroundColor: "#fff",
            boxShadow: 3,
            padding: 3,
            zIndex: 1300,
          }}
        >
          {/* <IconButton
            onClick={() => {
              setBillDetails(null);
              setFormView("");
            }}
          >
            <ArrowBackIcon />
          </IconButton> */}
          {formView === "ReadyForReview" && (
            <ReadyForReview
              invoiceData={billDetails}
              onClose={handleCloseForm}
            />
          )}
          {formView === "ReadyForPayment" && (
            <ReadyForPayment
              invoiceData={billDetails}
              onClose={handleCloseForm}
            />
          )}
          {formView === "PaidBillForm" && (
            <PaidBillForm invoiceData={billDetails} onClose={handleCloseForm} />
          )}
          {formView === "ReviewedForm" && (
            <ReviewedForm invoiceData={billDetails} onClose={handleCloseForm} />
          )}
        </Box>
      )}
      {showProformaInvoice && (
        <Box
          sx={{
            position: "fixed",
            top: "3%",
            left: "50%",
            transform: "translate(-50%, 0)",
            width: "100%",
            height: "100%",
            maxWidth: "1840px",
            maxHeight: "84vh",
            overflowY: "auto",
            backgroundColor: "transparent",
            borderRadius: "8px",
            zIndex: 1300,
            padding: 2,
            marginTop: "26px",
          }}
        >
          <ProformaInvoice
            invoiceData={billDetails}
            onClose={() => setShowProformaInvoice(false)}
          />
        </Box>
      )}

{showTaxInvoiceDialog && (
  <Box
    sx={{
      position: "fixed",
      top: "5%",
      left: "50%",
      transform: "translate(-50%, 0)",
      width: "100%",
      maxWidth: "800px",
      zIndex: 1300,
      backgroundColor: "transparent",
      overflowY: "auto",
    }}
  >
    <UploadTaxInvoice
      invoiceData={selectedInvoiceForTax}
      onClose={handleCloseTaxInvoiceDialog}
    />
  </Box>
)}

      {/* Upload Invoice Popup */}
      {showUploadForm && (
        <Box
          sx={{
            position: "fixed",
            top: "7%",
            left: "50%",
            transform: "translate(-50%, 0)",
            width: "100%",
            height: "100%",
            maxWidth: "1840px",
            maxHeight: "84vh",
            overflowY: "auto",
            backgroundColor: "transparent",
            borderRadius: "8px",
            zIndex: 1300,
            padding: 2,
            marginTop: "26px",
          }}
        >
          <UploadInvoice onClose={() => setShowUploadForm(false)} />
        </Box>
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{dialogContent.title}</DialogTitle>
        <DialogContent>
          <Typography>{dialogContent.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BillsList;